import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminLayout from '../layouts/AdminLayout';

function ProtectRoute() {
  // const { token: Usertoken } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.user);
  return currentUser.role === '1' || currentUser.role === '2' ? <AdminLayout /> : <Navigate to="/Login" />;
}
export default ProtectRoute;
